<template>
  <div class="d-flex mb-2 w-100 option" @click="updateValue()">
    <div
      :class="`boxes w-100 ps-2 ${value && !subdescription ? 'checked' : ''}`"
      style="margin-right: 0.7rem"
    >
      <img
        :src="isLocalImg ? logo : `${urlBucket}/${logo}`"
        @error="
          (e) => {
            e.target.src = `${urlBucket}/${imgOnError}`;
            hideSpinner(`${informName}${description.replace(/ /g, '-')}`);
          }
        "
        :width="widthLogo"
        class="mx-2"
        style="display: none; max-height: 55px"
        @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner(`${informName}${description.replace(/ /g, '-')}`);
          }
        "
      />
      <div
        :id="`${informName}${description.replace(/ /g, '-')}`"
        class="text-center mx-2"
      >
        <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
      </div>
      <!-- height="40px" -->
      <div class="ms-2">
        <p class="mb-0 fs-md">{{ description }}</p>
        <p v-if="subdescription" class="mb-0 fs-xs">
          Tap to let them know you're moving
        </p>
      </div>
    </div>
    <div
      :class="`boxes justify-content-center ${value ? 'checked' : ''}`"
      style="width: 70px"
    >
      <img
        v-if="value"
        :src="require('@/assets/check.png')"
        width="41px"
        height="33px"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Checkbox",
  props: {
    logo: [String],
    description: [String],
    subdescription: {
      type: Boolean,
      default: false,
    },
    informName: [String],
    value: [Boolean],
    widthLogo: {
      type: String,
      default: "40px",
    },
    isLocalImg: {
      type: Boolean,
      default: false,
    },
    imgOnError: {
      type: String,
      default: "",
    },
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    updateValue() {
      this.$store.commit("formStore/setField", [this.informName, !this.value]);
    },
  },
  computed: {
    ...mapState("formStore", ["urlBucket"]),
  },
};
</script>

<style lang="scss">
.option {
  cursor: pointer;
  height: 55px;
}

.boxes {
  border-radius: 6px;
  border: 1px solid white;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 11px;
}

.checked {
  border: 1px solid #2ec989;
  background-color: #1e9d68;
  color: white;
}
</style>
