<template>
  <div>
    <p class="fs-sm">You can type the year 😊</p>
    <div class="date-multi-select d-flex align-items-center">
      <vSelect
        :value="day"
        class="text-center fs-md me-2"
        label="day"
        autocomplete="off"
        :clearable="false"
        :options="days"
        :placeholder="`Day`"
        :multiple="false"
        @input="(e) => updateValue('day', e)"
      >
      </vSelect>

      <vSelect
        :value="month"
        class="month-input text-center fs-md me-2"
        label="month"
        autocomplete="off"
        :clearable="false"
        :options="months"
        :placeholder="`Month`"
        :multiple="false"
        @input="(e) => updateValue('month', e)"
      >
      </vSelect>

      <vSelect
        :value="year"
        class="year-input text-center fs-md me-2"
        label="year"
        autocomplete="off"
        :clearable="false"
        :options="years"
        :placeholder="`Year`"
        :multiple="false"
        @input="(e) => updateValue('year', e)"
      >
      </vSelect>
    </div>
  </div>

</template>
<script>
import vSelect from "vue-select";
import moment from "moment";

export default {
  name: "DateMultiSelect",
  props: {
    isDOB: [Boolean], //is Day of Birth?
    dateModel: [Date, String],
  },
  components: {
    vSelect,
  },
  data() {
    return {
      day: "",
      month: "",
      year: "",
      days: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      //months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      years: [], //generateArrayOfYears()
    };
  },
  mounted() {
    if (this.dateModel instanceof Date) {
      // console.log(this.dateModel);

      /* const format = (val) => val.length == 1 ? `0${val}` : val */

      let date = moment(this.dateModel);

      this.day = date.format("D");
      this.month = date.format("MMMM");
      this.year = this.dateModel.getFullYear();

      this.$emit("invalidDate", false);
    }

    const generateArrayOfYears = () => {
      var max = new Date().getFullYear()+1; /* - 5 */
      var min = max - 100;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i.toString());
      }
      this.years = years;
    };

    generateArrayOfYears();

    if(!this.isDOB){
      this.year = new Date().getFullYear()
    }
  },
  methods: {
    updateValue(itenName, value) {
      this[itenName] = value;
    },
    validDate() {
      let day = this.day;
      let month = this.month;
      let year = this.year;

      let dateValid = moment(`${day}/${month}/${year}`, "D/MMMM/YYYY", true).isValid();

      return dateValid;
    },
    formatDate() {
      if (this.day && this.month && this.year && this.validDate()) {
        let result = new Date(
          this.year,
          +moment().month(this.month).format("M") - 1,
          this.day,
          3, 0, 0, 0
          /* date.getHours(),
          date.getMinutes() */
        );
        this.$emit("invalidDate", this.validDate());

        this.$emit("formattedDate", result);
      } else {
        this.$emit("invalidDate", this.validDate());
      }
    },
  },
  watch: {
    day() {
      this.formatDate();
    },
    month() {
      this.formatDate();
    },
    year() {
      this.formatDate();
    },
  },
};
</script>
<style lang="scss">
.date-multi-select {
  .vs__actions {
    display: none;
  }
  .vs__selected {
    color: black;
  }
  .vs__dropdown-toggle {
    width: 80px;
    padding: 0.8rem 0.4rem !important;
    border-radius: 0.375rem;
    color: black;
  }

  .year-input {
    .vs__dropdown-toggle {
      width: 95px;
    }
  }
  .month-input {
    .vs__dropdown-toggle {
      width: 140px;
    }
  }

  .vs__dropdown-menu {
    color: black !important;
  }
}
</style>
