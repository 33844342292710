<template>
  <div class="page-your-home animate__animated animate__fadeIn">
    <div class="text-center my-4">
      <img
        :src="require('@/assets/banners/update-utilities.png')"
        class="img-fluid"
        style="display: none"
        @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner('spinner-banner');
          }
        "
      />
    </div>

    <div id="spinner-banner" class="text-center my-5">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>

    <h5 class="fs-la">Select which we can help you with</h5>
    <p class="fs-sm">We'll help you setup, close down or transfer</p>

    <div class="row mb-5">
      <div class="col">
        <!-- Council Tax -->
        <Checkbox
          :logo="'form/assets/hmrc.svg'"
          :description="'Council Tax'"
          :informName="'informCouncilTax'"
          :value="informCouncilTax"
        />
        <!-- Gas & Electricity -->
        <Checkbox
          :logo="'form/assets/energy.png'"
          :description="'Gas & Electricity'"
          :informName="'informEnergy'"
          :value="informEnergy"

        />

        <!-- Water & Sewerage -->
        <Checkbox
          :logo="'form/assets/water.svg'"
          :description="'Water & Sewerage'"
          :informName="'informWaterSewerage'"
          :value="informWaterSewerage"
        />

        <!-- Broadband & Media -->
        <!-- <Checkbox
          :logo="'form/assets/icons8-router.svg'"
          :description="'Broadband & Media'"
          :informName="'informBroadbandMedia'"
          :value="informBroadbandMedia"
        /> -->

        <!-- Insurance -->
        <!-- <Checkbox
          :isLocalImg="true"
          :logo="require('@/assets/insurance.png')"
          :description="'Insurance'"
          :informName="'informInsurance'"
          :value="informInsurance"
        /> -->
      </div>
    </div>

    <!-- informBroadbandMedia || informEnergy || informInsurance -->
    <div v-if="false">
      <h5 class="fs-la mb-3">
        How would you like to organise these services for your home?
        <i class="fa-solid fa-circle-info" @click="openInfoTooltip('ActionSheetYourHome')"></i>
      </h5>

      <div class="row mb-5">
        <div class="col">
          <!-- Speak to a human-->
          <Checkbox
            :logo="require('@/assets/landline-phone.png')"
            :isLocalImg="true"
            :description="'Speak to a human'"
            :informName="'speakToHuman'"
            :value="speakToHuman"
          />

          <!-- Complete it online -->
          <Checkbox
            :logo="require('@/assets/notebook.png')"
            :isLocalImg="true"
            :description="'Complete it online'"
            :informName="'completeItOnline'"
            :value="completeItOnline"
          />
          <p class="mb-0 fs-md" v-if="errors.servicesHome" style="color: #c64a45">
            * These fields are required
          </p>
        </div>
      </div>

      <!-- Best number -->
      <div class="mb-5" v-if="speakToHuman">
        <h5 class="fs-la">
          Phone number
          <i class="fa-solid fa-circle-info" @click="openInfoTooltip('ActionSheetPhoneNumber')"></i>
        </h5>
        <input
          type="number"
          placeholder="e.g 0776 888 8888"
          class="p-3 w-100 fs-md"
          :value="contactNumber"
          @input="(e) => updateValue('contactNumber', e.target.value)"
        />
        <p class="fs-md" v-if="errors.contactNumber" style="color: #c64a45">
          * The format of this number is invalid
        </p>
      </div>

      <!-- What is your move date? -->
      <div class="mb-5" v-if="iKnowMyMoveInDate">
        <h5 class="fs-la">What is your move-in date?</h5>
        <!-- <div class="d-flex align-items-center">
          <vSelect
            :value="dayMD"
            class="text-center fs-md me-2"
            label="day"
            :clearable="false"
            :options="days"
            :placeholder="`Day`"
            :multiple="false"
            @input="(e) => updateValue('dayMD', e)"
          >
          </vSelect>

          <vSelect
            :value="monthMD"
            class="text-center fs-md me-2"
            label="month"
            :clearable="false"
            :options="months"
            :placeholder="`Month`"
            :multiple="false"
            @input="(e) => updateValue('monthMD', e)"
          >
          </vSelect>

          <vSelect
            :value="yearMD"
            class="year-input text-center fs-md me-2"
            label="year"
            :clearable="false"
            :options="years"
            :placeholder="`Year`"
            :multiple="false"
            @input="(e) => updateValue('yearMD', e)"
          >
          </vSelect>
        </div> -->
        <DateMultiSelect
          :dateModel="moveInDate"
          @formattedDate="(value) => updateValue('moveInDate', value)"
          @invalidDate="(value) => onInvalidDate(value)"
        />
        <p class="fs-md" v-if="errors.moveInDate" style="color: #c64a45">* Enter a valid date</p>
      </div>
      <!-- I know my move in date-->
      <div class="row mt-2 mb-3">
        <div class="col d-block text-end">
          <label class="switch mb-2" style="margin-bottom: 0.5rem">
            <input type="checkbox" @change="checkClick()" :checked="iKnowMyMoveInDate" />
            <span class="slider round"></span>
          </label>

          <p class="fs-xs" v-if="iKnowMyMoveInDate">I know my move in date</p>
          <p class="fs-xs" v-if="!iKnowMyMoveInDate">I don't know my move-in date yet</p>
        </div>
      </div>

      <div
        class="label-pages d-flex p-2 px-2 align-items-center mb-2 animate__animated animate__bounceIn"
        v-if="completeItOnline && iKnowMyMoveInDate"
      >
        <img :src="require('@/assets/check-y.png')" width="25px" class="ms-2 me-2" alt="" />
        <p class="ms-1 fs-sm mb-0">
          Excellent, now let's organise your updates and sort your services
        </p>
      </div>

      <div
        class="label-pages d-flex p-2 px-3 align-items-center mb-2 animate__animated animate__bounceIn"
        v-if="speakToHuman && iKnowMyMoveInDate"
      >
        <img :src="require('@/assets/check-y.png')" width="30px" class="ms-2 me-2" alt="" />
        <p class="ms-1 fs-sm mb-0">
          Excellent, we'll arrange a call to help you get setup with those services in your new home
        </p>
      </div>

      <div
        class="label-pages d-flex p-2 px-3 align-items-center mb-2 animate__animated animate__bounceIn"
        v-if="(completeItOnline || speakToHuman) && !iKnowMyMoveInDate"
      >
        <img :src="require('@/assets/check-y.png')" width="30px" class="ms-2 me-2" alt="" />
        <p class="ms-1 fs-sm mb-0">
          Great, you can add this in the dashboard when you know when you're moving
        </p>
      </div>
    </div>

    <!-- !informBroadbandMedia && !informEnergy & !informInsurance-->
    <div v-if="false" class="label-pages d-flex p-2 px-3 align-items-center mb-2">
      <img
        :src="require('@/assets/light.png')"
        width="30px"
        class="ms-2 me-2"
        style="display: none"
        @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner('light');
          }
        "
      />
      <div id="light" class="text-center ms-2 me-2">
        <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
      </div>
      <p class="ms-1 fs-sm mb-0">
        You only need to notify previous suppliers if you pay for the bills
      </p>
    </div>

    <!-- I am the responsible for the bills -->
    <!-- <div class="row mt-2">
      <div class="col d-block text-end">
        <label class="switch mb-2">
          <input type="checkbox" @change="checkClick()" :checked="responsibleForTheBills" />
          <span class="slider round"></span>
        </label>

        <p class="fs-xs">I am the responsible for the bills</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Checkbox from "../components/Checkbox";
import pcToWater from "@/data/postCodeToWaterSupplier";
import { navigateTo } from "../router/index.js";
import moment from "moment";
// import pcToPrice from "@/data/postCodeToPrice";
import vSelect from "vue-select";
import DateMultiSelect from "../components/DateMultiSelect";
import  {setRoute,clearRoutes}  from "../router/setRoute.js";

// @ is an alias to /src

export default {
  name: "PageYourHome",
  components: {
    Checkbox,
    vSelect,
    DateMultiSelect,
  },
  data() {
    return {
      validDate: false,
      errors: {
        contactNumber: false,
        moveInDate: false,
        servicesHome: false,
      },
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    onInvalidDate(val) {
      this.validDate = val;
    },
    validatePhoneNumber() {
      return (
        (this.contactNumber.indexOf("07") == 0 && this.contactNumber.length == 11) ||
        (this.contactNumber.indexOf("00447") == 0 && this.contactNumber.length == 14) ||
        (this.contactNumber.indexOf("447") == 0 && this.contactNumber.length == 12)
      );
    },
    validateInputs() {
      /*Error Inputs for 'Speak to a human' or 'Complete it online'*/
      // if (
      //   (this.informBroadbandMedia || this.informEnergy || this.informInsurance) &&
      //   !this.completeItOnline &&
      //   !this.speakToHuman
      // ) {
      //   this.errors.servicesHome = true;
      // } else {
      //   this.errors.servicesHome = false;
      // }
      /* Contact Number */
      // if (this.speakToHuman && this.validatePhoneNumber()) {
      //   this.errors.contactNumber = false;
      // } else {
      //   this.errors.contactNumber = !this.speakToHuman ? false : true;
      // }
      /* Move In date Input */
      // if (
      //   (this.informBroadbandMedia || this.informEnergy || this.informInsurance) &&
      //   this.iKnowMyMoveInDate &&
      //   !this.validDate
      // ) {
      //   this.errors.moveInDate = true;
      // } else {
      //   this.errors.moveInDate = false;
      // }
      // if (this.moveInDate instanceof Date) {
      //   let years = moment().diff(this.moveInDate, "years");
      //   if (years >= 100 || years < 0) {
      //     this.errors.moveInDate = true;
      //   } else {
      //     this.errors.moveInDate = false;
      //   }
      // }
    },
    checkClick() {
      this.$store.commit("formStore/setField", [`iKnowMyMoveInDate`, !this.iKnowMyMoveInDate]);
      if (!this.iKnowMyMoveInDate) {
        this.errors.moveInDate = false;

        // this.$store.commit("formStore/setField", [`dayMD`, ""]);
        // this.$store.commit("formStore/setField", [`monthMD`, ""]);
        // this.$store.commit("formStore/setField", [`yearMD`, ""]);
      }
    },
    setCouncilsWater() {
      if (this.addressManually) {
        //When the user has enabled manual entry of the address

        const getFullComplete = (id, water, council, type) => {
          fetch(`${this.baseURL}/addresses/getFullComplete?stream=${id}`, {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            res.json().then((json) => {
              // console.log(json)

              this.$store.commit("formStore/setField", [
                water,
                pcToWater[json.postcode.split(" ")[0]],
              ]);
              this.$store.commit("formStore/setField", [council, json.district ?? json.county]);
            });
          });
        };

        const addressFinderId = (postcode, water, council, type) => {
          fetch(`${this.baseURL}/addresses/autoComplete?stream=${postcode}`, {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res) => res.json())
            .then((res) => {
              let id = res.suggestions[0].id;
              getFullComplete(id, water, council, type);
            });
        };

        addressFinderId(this.moveToPostCode, "newWater", "newCouncil", "toPostCode");
        addressFinderId(this.moveFromPostCode, "oldWater", "oldCouncil", "fromPostCode");
      }
    },

    updateValue(itemName, value) {
      // console.log("updateValue", value);
      this.$store.commit("formStore/setField", [`${itemName}`, value]);

      //Move In Date Input
      // if (this.iKnowMyMoveInDate) {
      //   if (this.validDate()) {
      //     let date = new Date();
      //     this.$store.commit("formStore/setField", [
      //       "moveInDate",
      //       new Date(
      //         this.yearMD,
      //         +this.monthMD - 1,
      //         this.dayMD,
      //         date.getHours(),
      //         date.getMinutes()
      //       ),
      //     ]);
      //   }
      // }
    },
    // validDate() {
    //   let day = this.dayMD;
    //   let month = this.monthMD;
    //   let year = this.yearMD;
    //   console.log(this.moveInDate);

    //   let dateValid = moment(this.moveInDate).isValid();
    //   console.log(dateValid);

    //   return dateValid;
    // },
    openInfoTooltip(tooltipName) {
      this.$store.commit("setField", ["currentTooltip", tooltipName]);
      const pushbar = new Pushbar({
        blur: true,
        overlay: true,
      });
      pushbar.open("action-sheet");
    },
  },
  mounted() {
    clearRoutes()

    const generateArrayOfYears = () => {
      var max = new Date().getFullYear(); /* - 5 */
      var min = max - 100;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i.toString());
      }
      this.years = years;
    };

    this.setCouncilsWater();
    generateArrayOfYears();
    this.$store.commit("setField", ["titleNavbar", "Your Home"]);
    this.$store.commit("setField", ["step3", true]);

    const validatePage = () => {
      // let route = "/about-you";

      // const setRoute = () => {
      //   if (this.informDVLA) {
      //     route = "/dvla";
      //     return;
      //   }
      //   if (this.informNHS || this.informDentist) {
      //     route = "/general-info-2";
      //     return;
      //   }

      //   if (this.informInsurance) {
      //     route = "/insurance";
      //     return;
      //   }

      //   if (this.informPets) {
      //     route = "/pets";
      //     return;
      //   }

      //   if (
      //     this.informPension ||
      //     this.informCharities ||
      //     this.informGyms ||
      //     this.informHomecare ||
      //     this.informBreakdown
      //   ) {
      //     route = "/general-info-3";
      //     return;
      //   }

      //   if (this.informBanks) {
      //     route = "/banks";
      //     return;
      //   }

      //   if (this.informCouncilTax || this.informWaterSewerage || this.informEnergy) {
      //     route = "/councils";
      //     return;
      //   }

      //   if (this.informMobile) {
      //     route = "/mobile-providers";
      //     return;
      //   }

      //   if (this.informLoyalty) {
      //     route = "/loyalties";
      //     return;
      //   }

      //   if (this.informOpticians) {
      //     route = "/opticians";
      //     return;
      //   }
      // };
      // setRoute();

      //If we already have a valid and complete phone number we do not ask it on the about you page.
      this.updateValue("showInputContactNumber", this.validatePhoneNumber() ? false : true);

      this.updateValue(
        "completeItOnline",
        this.informBroadbandMedia || this.informEnergy || this.informInsurance ? true : false
      );

      this.validateInputs();

      if (!Object.values(this.errors).includes(true)) {
        navigateTo(setRoute(this.state));
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },
  watch: {
    informWaterSewerage(newVal) {
      if (newVal == false) {
        this.updateValue(`informNewWater`, false);
        this.updateValue(`informNewWater`, false);
      }
    },
    informCouncilTax(newVal) {
      if (newVal == false) {
        this.updateValue(`informOldCouncil`, false);
        this.updateValue(`informNewCouncil`, false);
      }
    },
    informEnergy(newVal) {
      if (!newVal) {
        this.updateValue("selectedEnergySupplier", []);
        this.updateValue("selectedGasSupplier", []);
      }
    },
    iKnowMyMoveInDate(newVal) {
      if (newVal) {
        this.$store.commit("formStore/setField", [`showInputMoveInDate`, false]);
      } else {
        this.$store.commit("formStore/setField", [`showInputMoveInDate`, true]);
      }
    },
    completeItOnline(newVal) {
      if (newVal) {
        this.$store.commit("formStore/setField", [`speakToHuman`, false]);
      }
    },
    speakToHuman(newVal) {
      if (newVal) {
        this.$store.commit("formStore/setField", [`completeItOnline`, false]);
      }
      if (newVal && this.contactNumber.length > 0) {
        this.updateValue("showInputContactNumber", false);
      } else {
        this.updateValue("showInputContactNumber", true);
      }
    },
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "baseURL",
      "urlBucket",
      "informNHS",
      "informDentist",
      "informCouncilTax",
      "informEnergy",
      "informWaterSewerage",
      "informBroadbandMedia",
      "informEnergy",
      "informDVLA",
      "informBanks",
      "informPension",
      "informCharities",
      "informGyms",
      "informMobile",
      "informLoyalty",
      "informInsurance",
      "informBreakdown",
      "informHomecare",
      "informPets",
      "responsibleForTheBills",
      "moveInDate",
      "moveToPostCode",
      "moveFromPostCode",
      "addressManually",
      "informEnergy",
      "speakToHuman",
      "completeItOnline",
      "contactNumber",
      "dayMD",
      "monthMD",
      "yearMD",
      "iKnowMyMoveInDate",
      "showInputMoveInDate",
      "informOpticians",
    ]),
    ...mapGetters("formStore", ["state"]),
  },

};
</script>
<style lang="scss">
.page-your-home {
  .vs__actions {
    display: none;
  }
  .vs__selected {
    color: black;
  }
  .vs__dropdown-toggle {
    width: 85px;
    padding: 0.8rem !important;
    border-radius: 0.375rem;
    color: black;
  }

  .vs__dropdown-menu {
    color: black !important;
  }

  .slider {
    background-color: #f8644c;
  }
}
</style>
